/* eslint-disable jsx-a11y/anchor-is-valid */
// a play store for games.
// import and use bootstrap

// create a navbar with a logo, login and sign up buttons
// create a carousel slider with 3 images
// create a row with 4 game categories. each category has an icon and a name

import { useEffect, useState } from "react";
import "./Games.scss";
import GAMES from "../data/games.json";

import Page from "../components/Page";
import { Modal } from "react-bootstrap";
import { useHeGame } from "../lib/GameProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import GiftModal from "../components/GiftModal";

function GamesPage() {
  const [games] = useState(GAMES);
  const [categories] = useState([
    {
      id: 2,
      name: "Learning",
      icon: "/images/icons/book.png",
    },
    {
      id: 3,
      name: "Arcade",
      icon: "/images/icons/spaceship.png",
    },
    {
      id: 4,
      name: "Adventure",
      icon: "/images/icons/location.png",
    },
    {
      id: 5,
      name: "Puzzle",
      icon: "/images/icons/jigsaw.png",
    },
    {
      id: 6,
      name: "Sports",
      icon: "/images/icons/sports.png",
    },
    {
      id: 1,
      name: "Racing",
      icon: "/images/icons/race.png",
    },
    {
      id: 7,
      name: "Board",
      icon: "/images/icons/board-game.png",
    },
  ]);

  const [promotions, setPromotions] = useState<any[]>([]);
  const [, setRedirect] = useState("");

  useEffect(() => {
    // Update promotional banners using random games

    const randomPromotions = [];

    for (let i = 0; i < 3; i++) {
      const randomIndex = Math.floor(Math.random() * games.length);
      randomPromotions.push(games[randomIndex]);
    }

    setPromotions(randomPromotions);

    // initialize carousel
    // const myCarousel = document.querySelector("#carouselExampleControls");
    // const carousel = new (window as any).bootstrap.Carousel(myCarousel, {
    //   interval: 2000,
    //   wrap: true,
    // });
  }, [games]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    isLoggedIn,
    subscribed,
    message,
    loading,
    btnLoading,
    showModal,
    handleSubscription,
    logout
  } = useHeGame({ navigate, searchParams })

  useEffect(() => {
    if (!isLoggedIn) {
      handleSubscription?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <Page title="Games">
      <div className="App">
        {(loading || btnLoading) && <div className="flex justify-around" style={{ height: '100vh', alignItems: 'center' }}>
          <div className="text-center">
            {message && <h5>{message}</h5>}
            <div className="spinner-border text-info" style={{ width: '5rem', height: '5rem', marginTop: '20px' }} role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>}

        <nav className="navbar navbar-expand-lg navbar-light  shadow">
          <div className="container d-flex justify-content-between align-items-center">
            <a className="navbar-brand fw-bold text-white" href="#">
              {process.env.REACT_APP_GAME_NAME || "GameFrenzy"}
            </a>

            <div className="d-flex">
              {isLoggedIn ? <button className="btn btn-sm btn-gradient " onClick={() => { logout?.() }}>
                Logout
              </button> : <button className="btn btn-sm btn-gradient " onClick={() => { logout?.() }}>
                Login
              </button>}
              {/* <a className='btn btn-sm btn-gradient' href='#'>
              Sign Up
            </a> */}
            </div>
          </div>
        </nav>

        <div className="container my-4">
          {/* game category icons */}

          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              overflowX: "auto",
            }}
          >
            {categories.map((category, index) => (
              <a
                key={index}
                href={`#${category.name}`}
                style={{
                  textDecoration: "none",
                }}
                className=" d-flex flex-column align-items-center mx-3 mx-md-4 mb-2"
              >
                <img src={category.icon} alt={category.name} width={40} />

                <div className="mt-1">
                  <small
                    className="text-uppercase fw-bold"
                    style={{
                      fontSize: "0.6rem",
                      opacity: 0.8,
                      color: "#fff",
                    }}
                  >
                    {category.name}
                  </small>
                </div>
              </a>
            ))}
          </div>

          {/* promotional carousel slider */}
          <div className="row my-4">
            <div className="col-12">
              <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                  {promotions.map((promotion, index) => (
                    <div key={index} className="carousel-item d-flex justify-content-center">
                      <img src={promotion.banner} className="d-block w-100 rounded banner-img" alt="..." />

                      <div className="carousel-caption d-none d-md-block">
                        <h5 className="text-shadow fw-bold">{promotion.name}</h5>
                        <p className="text-shadow">{promotion.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* category rows with game cards. game cards have game banner, play now and demo buttons*/}

          <div className="row">
            <div className="col-12 my-4">
              <p className="fw-bold m-0 text-uppercase">
                <i className="fas fa-gamepad me-2"></i>
                <small>All Games</small>
              </p>

              <div className="row">
                {games.map((game, i) => (
                  <div key={i} className="col-6 col-md-4 col-lg-4 my-1 p-1">
                    <Card game={game} setRedirect={setRedirect} isSubscribed={subscribed} />
                  </div>
                ))}
              </div>
            </div>

            {/* loop through categories and filter games for each category */}
            {categories.map((category, i) => (
              <div key={i} className="col-12 my-4" id={category.name}>
                <div className="fw-bold m-0 text-uppercase mb-2 d-flex align-items-center">
                  <img src={category.icon} alt={category.name} width={30} className="me-2" />
                  <div>{category.name}</div>
                </div>

                <div className="row">
                  {games
                    .filter((game) => game.category === category.name)
                    .map((game, i) => (
                      <div key={i} className="col-6 col-md-4 col-lg-4 my-1 p-1">
                        <Card game={game} setRedirect={setRedirect} isSubscribed={subscribed} />
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* footer */}
        <div className="bg-dark text-white py-4">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3 ">
                <p className="fw-bold">Fun Games</p>
                <p>Fun Games is a product of Onfon Media Limited</p>

                <div className="d-flex">
                  <a href="#" className="me-2">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#" className="me-2">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#" className="me-2">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#" className="me-2">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3 ">
                <p className="fw-bold">Quick Links</p>
                <ul className="list-unstyled">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <a href="#">About</a>
                  </li>
                  <li>
                    <a href="#">Contact</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div>

              <div className="col-12 col-md-6 col-lg-3 ">
                <p className="fw-bold">Contact Us</p>
                {/* <p>
                  <i className="fas fa-phone-alt me-2"></i> +254 726 354 124
                </p> */}
                <p>
                  <i className="fas fa-envelope me-2"></i>

                  <a href="mailto:info@gamefrenzy.com">info@gamefrenzy.com</a>
                </p>

                <p>
                  <i className="fas fa-map-marker-alt me-2"></i>
                  <a href="#">Nairobi, Kenya</a>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Gift modal */}
        <GiftModal />

        <Modal show={showModal} onHide={() => { logout?.() }} centered>
          <Modal.Body>
            <div className="text-center">
              <div style={{ marginBottom: '5px' }}>
                <p>{message}</p>
              </div>
              <button className="w-full" onClick={() => { logout?.() }} data-dismiss="modal">
                Refresh
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Page>
  );
}

const Card = ({ game }: any) => {
  const goToGame = (href: any) => {
    window.location = href;
  };

  // console.log(setRedirect, isSubscribed)

  return (
    <div className="" onClick={() => goToGame(game.play)}>
      <img src={game.banner} className="game-card card-img-top" alt="..." />
      <div className="card-body">
        {/* <h5 className='card-title'>{game.name}</h5>
         <p className='card-text'>{game.description}</p> */}
        {/* <div className='d-flex justify-content-between p-1 pt-2'>
          <button
            type='button'
            onClick={() => setRedirect(game.play)}
            className='btn btn-sm btn-gradient'
            >
            Play Now
          </button>
          <a
            href={game.demo}
            className='btn btn-sm btn-outline-light'
            // data-bs-toggle='modal'
            // data-bs-target='#exampleModal'
          >
            Demo
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default GamesPage;
